// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
// import 'abortcontroller-polyfill';

// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
// import store from './_helpers/store'
// import { setUser } from "./_helpers/localStorage"
// import App from './app';
// import * as serviceWorker from './serviceWorker'

// store.subscribe(() => {
// const state = store.getState();
//   setUser(state.user);
// })

// const ua = window.navigator.userAgent;
// const msie = ua.indexOf('MSIE ');
// // IE 10 or older => return version number
// // return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
// const widgetJs = document.getElementById('pandai-widget-js');

// const componentToRender = msie > 0 ?
//   <div style={{ textAlign: 'center'}}>
//     <h3>Please use Internet Explorer 11 and above.</h3>
//   </div>
//   :
//   <Provider store={store}>
//       <App 
//         chatbotReferral={widgetJs.getAttribute('data-chatbot-referral')}
//         chatOpenByDefault={widgetJs.getAttribute('data-chat-open-by-default')}
//         autoRestartSession={widgetJs.getAttribute('data-auto-restart-session')}
//         enableFeedback={widgetJs.getAttribute('data-enable-feedback')}
//         hideRestartOnFeedback={widgetJs.getAttribute('data-hide-restart-on-feedback')}
//         enableFileUpload={widgetJs.getAttribute('data-enable-file-upload')}
//         showWatermark={widgetJs.getAttribute('data-show-watermark')}
//         useCustomLauncher={widgetJs.getAttribute('data-use-custom-launcher')}
//         customLauncherHeight={widgetJs.getAttribute('data-custom-launcher-height')}
//         customLauncherWidth={widgetJs.getAttribute('data-custom-launcher-width')}
//         persistenMenuButton={widgetJs.getAttribute('data-persistent-menu-button')}
//       />
//   </Provider>
// ReactDOM.render(componentToRender, document.getElementById('pandai-widget-root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './_helpers/store'
import { setUser } from "./_helpers/localStorage"
import App from './app';
import * as serviceWorker from './serviceWorker'

store.subscribe(() => {
  const state = store.getState();
  setUser(state.user);
})

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
// IE 10 or older => return version number
// return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);

const componentToRender = msie > 0 ?
  <div style={{ textAlign: 'center' }}>
    <h3>Please use Internet Explorer 11 and above.</h3>
  </div>
  :
  <Provider store={store}>
    <App />
  </Provider>

ReactDOM.render(componentToRender, document.getElementById('pandai-widget-root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();