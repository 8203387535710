import React, { useState, useEffect, useCallback, useRef } from 'react';
import clsx from 'clsx'
import { Backdrop, Grid, Hidden } from '@material-ui/core';
import humps from 'humps'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import ChatActiveScreen from './pages/chatActiveScreen'
import WelcomeScreen from './pages/welcomeScreen'
import PostSessionScreen from './pages/postSessionScreen'
import UserInput from './elements/userInput'
import PersistentMenu from '../PersistentMenu'
import Form from '../Form'
import SideBar from '../SideBar'

import useStyles from './styles/chatAreaStyles'
import { startSession, endSession } from '../../_actions/user.actions'
import { useUserContext } from '../../_context/user.context'
import { useConfigContext } from '../../_context/config.context'
import { useDropzone } from "react-dropzone";
import { Buffer } from 'buffer';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL ? process.env.REACT_APP_SOCKET_URL : '>> MISSING .ENV CONFIG'
const disableLogs = process.env.REACT_APP_STAGE === 'test'

const consoleStyles = 'color: #08576f; font-size: 20px; margin-left: 15px; margin-top: 10px;'
const consoleStylesSecondary = 'color: #11e00f; font-size: 12px; margin-left: 15px;'

export default function ChatArea(props) {
  const { config } = useConfigContext()
  const { chatIsOpen, launchButtonSize, toggleOpenState, triggerNotification } = props
  const {
    AUTO_RESTART_SESSION,
    ENABLE_FEEDBACK,
    ENABLE_FILE_UPLOAD,
    SHOW_USER_FORM_RESPONSE,
    USE_SESSION,
    WIDE_MODE,
    NAVIGATION_ITEMS,
    WELCOME_SCREEN_SETTINGS,
    SHOW_WATERMARK,
    TRAILING_MESSAGES_DISPLAYED,
    CHATBOT_REFERRAL
  } = config
  /* Function to modify the preset width on stylesheets based on current window size, useEffect below listens for any resize events and calls this fn */
  const isClient = typeof window === 'object';
  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }, [isClient])
  /* ----------  */

  const [notificationVisible, setNotificationVisible] = useState(true)
  const [disconnectedDialogOpen, setDisconnectedDialogOpen] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [focus, setFocus] = useState(false)
  const [windowSize, setWindowSize] = useState(getSize);
  const [webSocket, setWebSocket] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [unMountClass, setUnMountClass] = useState('');
  const [messages, setMessages] = useState([]);
  const [sessionEndedByUser, setSessionEndedByUser] = useState(false);
  const [loading, setLoading] = useState(false)
  const [socketReady, setSocketReady] = useState(false)
  const [resetUserMsg, setResetUserMsg] = useState(false)
  const fileUploadInputRef = useRef(null)
  const [files, setFiles] = useState([])
  const sendBtnRef = useRef(null)

  const { user, dispatch } = useUserContext()
  const classes = useStyles({ launchButtonSize, ...config });

  const triggerUserMsgReset = useCallback(() => {
    setResetUserMsg(true)
    setTimeout(() => {
      if (resetUserMsg) setResetUserMsg(false)
    }, 300)
  }, [resetUserMsg])

  const handleFiles = useCallback(receivedFiles => {
    setFiles(receivedFiles);
    sendBtnRef.current.focus()
  }, []);

  const handleInputFiles = useCallback(e => {
    handleFiles(e.target.files);
  }, [handleFiles]);

  const onDrop = useCallback(acceptedFiles => {
    handleFiles(acceptedFiles);
  }, [handleFiles]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const resetInput = () => {
    setFiles([])
    fileUploadInputRef.current.value = ''
  }

  const toggleDialog = () => {
    setDisconnectedDialogOpen(!disconnectedDialogOpen)
  }

  const triggerInputFocusScroll = () => {
    setFocus(!focus)
  }

  const toggleMenu = () => {
    setMenuOpen(state => !state);
  };

  const updateReadState = useCallback(id => {
    let currentMsgsClone = JSON.parse(JSON.stringify(messages))
    const itemIndex = currentMsgsClone.findIndex(item => item.id === id)
    if (itemIndex !== -1) {
      currentMsgsClone[itemIndex].readState = true
      setMessages(currentMsgsClone.slice(currentMsgsClone.length - TRAILING_MESSAGES_DISPLAYED * 2))
    }

  }, [messages, TRAILING_MESSAGES_DISPLAYED])

  const restartSocket = () => {
    if (!USE_SESSION) dispatch(endSession()) // disabled endSession to prevent session from being deleted/closed
    triggerUserMsgReset()
    setUnMountClass('')
    setMessages([])
    setDisconnectedDialogOpen(false)
    setSessionEndedByUser(false)
    setInitialLoad(true)
    setSocketReady(false)
  }

  const handleEndChatBtn = useCallback((keepAlive) => {
    if (webSocket && !keepAlive) {
      triggerUserMsgReset()
      setUnMountClass('')
      setMessages([])
      dispatch(endSession())
      webSocket.close(1000)
      if (AUTO_RESTART_SESSION) setInitialLoad(true)
    }
    if (!ENABLE_FEEDBACK && WIDE_MODE) {
      return
    } else if (!ENABLE_FEEDBACK) {
      toggleOpenState()
    } else {
      setSessionEndedByUser(true)
    }
  }, [WIDE_MODE, ENABLE_FEEDBACK, AUTO_RESTART_SESSION, dispatch, toggleOpenState, webSocket, triggerUserMsgReset])

  const endFeedback = () => {
    if (!WIDE_MODE) toggleOpenState()
    // handleEndChatBtn()
    // setTimeout(() => setSessionEndedByUser(false), 3000) //Removed reset. Chat needs to be refreshed to restart socket.
  }

  const sendFile = async (files) => {
    const acceptedFileTypes = ['image', 'video']
    let payload = {
      type: '',
      data: {
        items: []
      }
    }
    payload.type =
      Object.values(files).every(v => v.type?.split('/')[0] === files[0]?.type?.split('/')[0])
        &&
        acceptedFileTypes.indexOf(files[0]?.type?.split('/')[0]) !== -1
        ?
        `${files[0]?.type?.split('/')[0]}s` : 'files'


    for (let i = 0; i < files.length; i++) {
      payload.data.items.push({
        fileExtension: files[i].name.split('.').pop(),
        fileName: files[i].name
      })
    }
    const sizeChunk = Buffer.from(`${JSON.stringify(humps.decamelizeKeys(payload)).length}`.padStart(8, '0'))
    const payloadChunk = Buffer.from(JSON.stringify(humps.decamelizeKeys(payload)))
    const reader = new FileReader();
    let fileChunk = new ArrayBuffer();
    reader.onload = function (e) {

      fileChunk = Buffer.from(e.target.result)
      const combinedBytes = Buffer.concat([sizeChunk, payloadChunk, fileChunk])
      payload.id = uuid()
      payload.createdAt = new Date()
      payload.createdBy = 'user'
      updateMessages(payload)
      webSocket.send(combinedBytes)
    }
    reader.readAsArrayBuffer(files[0])
    // webSocket.send(JSON.stringify(humps.decamelizeKeys(payload)));
  }


  const sendForm = payload => {
    updateMessages(payload)
    webSocket.send(JSON.stringify(humps.decamelizeKeys(payload)));
  }

  /* ===  Update local state with new messages  ==== */
  const updateMessages = useCallback(newMessage => {

    const availableTypes = [
      'message',
      'button_template',
      'generic_template',
      'postback',
      'image',
      'file',
      'video',
      'form',
      'typing_on',
      'typing_off',
      'referral',
      'livechat',
      'notification',
      'images', //file types below are used for showing file uploads
      'files',
      'videos',
      'audios',
    ]

    if (newMessage.messageReceivedId) {
      updateReadState(newMessage?.messageReceivedId)
      return
    }

    if (!availableTypes.includes(newMessage.type)) {
      console.error('data type cannot be parsed yet, message not added to chat');
      return
    }

    // handle typing indicator, no need to update messages
    if (newMessage.type === 'typing_on') {
      setLoading(true)
      setTimeout(() => {
        if (loading) setLoading(false)
      }, 5000)
      return
    } else if (newMessage.type === 'typing_off') {
      setLoading(false)
      return
    } else {
      setLoading(false)
    }

    let currentMsgsClone = JSON.parse(JSON.stringify(messages))
    currentMsgsClone.push(newMessage)
    // animate out welcome screen if this is the first message a user is sending on a new chat session
    if (messages.length === 0) {
      setUnMountClass('welcome-out')
      setMessages(currentMsgsClone.slice(currentMsgsClone.length - TRAILING_MESSAGES_DISPLAYED * 2))
      setTimeout(() => {
        setUnMountClass('')
      }, 940)
    } else {
      setMessages(currentMsgsClone.slice(currentMsgsClone.length - TRAILING_MESSAGES_DISPLAYED * 2))
    }
  }, [messages, loading, updateReadState, TRAILING_MESSAGES_DISPLAYED])

  /*  ==== Send message to server  ===== */
  const sendMessage = (postback, userMsg) => {
    /* if user has not typed any message, no postback is available, skip */
    if (userMsg === '' && !postback) return
    /* check that websocket has been initialized and is open */
    if (!webSocket || webSocket.readyState !== 1) {
      alert(`cant connect :: webSocket.readyState - ${webSocket.readyState}`)
      return
    }
    const messageInTemplate = postback ?
      {
        type: 'postback',
        data: postback,
        id: uuid(),
        createdAt: new Date(),
        createdBy: 'user'
      }
      :
      {
        type: "message",
        data: {
          text: userMsg
        },
        id: uuid(),
        readState: false,
        createdAt: new Date(),
        createdBy: 'user'
      }
    updateMessages(messageInTemplate)
    triggerUserMsgReset()
    webSocket.send(JSON.stringify(humps.decamelizeKeys(messageInTemplate)));
  }

  /* Checks for whether window is accessible (in order to obtain window.innerWidth and innerHeight)*/
  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }
    /* listen for resize event and toggle height of chat accordingly */
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getSize, isClient]);

  /* ==== START SOCKET CONNECTION === */
  const startSocket = useCallback(() => {
    if (disableLogs) return
    /* if this is not the initial load & auto restart is disabled, skip */
    if (!initialLoad && !AUTO_RESTART_SESSION) {
      return
    } else if (initialLoad && !AUTO_RESTART_SESSION) {
      setInitialLoad(false)
    }
    console.log('%c||========= STARTING SOCKET SESSION =========||', consoleStyles);
    const sessionId = user.sessionId
    const sendOld = messages.length === 0 //if there are messages, don't pull archived messages
    const params = window?.location?.search ? `${window?.location?.search}&tz_offset=${moment().utcOffset()}${CHATBOT_REFERRAL ? "&ref=" + CHATBOT_REFERRAL : ""}` : `?tz_offset=${moment().utcOffset()}${CHATBOT_REFERRAL ? "&ref=" + CHATBOT_REFERRAL : ""}`
    const full_socket_url = `${SOCKET_URL}`.startsWith('wss') ? `${SOCKET_URL}` : `wss:\/\/${window.location.hostname}${SOCKET_URL}` //If query params are provided, pass to socket
    const urlWithParams = `${full_socket_url}${params}` //If query params are provided, pass to socket
    const url =
      USE_SESSION && sessionId ?
        `${full_socket_url}?session_id=${sessionId}&tz_offset=${moment().utcOffset()}&send=${sendOld}${params.split('?')[1] ? '&' + params.split('?')[1] : ''}` //since we're appending to the end of the socket url, need to check here again
        :
        urlWithParams
    const ws = new WebSocket(url);
    ws.onopen = (res) => {
      setWebSocket(ws)
      console.log(`%cSocket connection started @ ${new Date()} \n`, consoleStylesSecondary);
    }
  }, [user.sessionId, messages.length, initialLoad, AUTO_RESTART_SESSION, USE_SESSION])

  useEffect(() => {
    /* if feedback component is enabled, only restart socket if the feedback form has been completed and closed */
    if (ENABLE_FEEDBACK) {
      if (chatIsOpen && webSocket === null && !sessionEndedByUser) {
        startSocket()
      }
    } else if (WIDE_MODE) {
      /* ON WIDE_MODE, ignore chat open state, if socket has not yet been initialized, start a new socket */
      if (WIDE_MODE && webSocket === null) {
        startSocket()
      }
    } else {
      /* otherwise, if chat window is open and socket has not yet been initialized, start a new socket */
      if (chatIsOpen && webSocket === null) {
        startSocket()
      }
    }
  }, [chatIsOpen, startSocket, webSocket, sessionEndedByUser, ENABLE_FEEDBACK, WIDE_MODE])

  /* listen to message updates from server */
  useEffect(() => {
    if (webSocket) {
      webSocket.onmessage = function (event) {
        // remove snakecase
        const data = humps.camelizeKeys(JSON.parse(event.data))
        // create UUID for animation library
        data.id = uuid()
        data.createdAt = moment(data.createdAt).add(moment().utcOffset(), 'm')
        if (data.sessionId) {
          /* add sessionId to localstorage user object */
          dispatch(startSession(data.sessionId))
          if (!socketReady) setSocketReady(true)
        } else {
          if (data?.data?.action === 'end-session') {           /* if end session trigger has been sent, end chat here */
            handleEndChatBtn(ENABLE_FEEDBACK) //if feedback is not enabled, end the socket connection, otherwise only end after feedback is complete.
          } else {
            /* update UI with new messages */
            console.log(`::: `, data);
            if (!socketReady) setSocketReady(true)

            if (data.createdBy === 'user') {
              data.readState = true
            }
            triggerNotification()
            updateMessages(data)
          }
        }
      }

      webSocket.onclose = function (event) {
        setWebSocket(null)
        if (!AUTO_RESTART_SESSION) setDisconnectedDialogOpen(true)
        console.log("WebSocket is closed.");
      };
      // webSocket.onerror = function(event) {
      //   console.log("WebSocket received error: ", event.data);
      // };
    }
  }, [
    webSocket,
    updateMessages,
    startSocket,
    dispatch,
    triggerNotification,
    handleEndChatBtn,
    ENABLE_FEEDBACK,
    AUTO_RESTART_SESSION,
    socketReady,
    user.sessionId
  ])

  useEffect(() => {
    window.addEventListener("orientationchange", () => {
      setWindowSize(getSize);
    })
  }, [getSize])

  // useEffect(() => {
  //   setTimeout(() => {
  //     setDisconnectedDialogOpen(true);
  //   }, 3000);
  // }, []);

  const lastMessage = messages[messages.length - 1]
  const showForm =
    messages.length > 0 && lastMessage.type === 'form' && lastMessage.createdBy === 'bot'
  // (lastMessage.createdBy === 'bot' ||  // show form component if the last message contains a form & is from the server or
  //   (lastMessage.createdBy === 'user' && lastMessage.data.action === 'submit' && !SHOW_USER_FORM_RESPONSE) // show form component if the last message is from the user and its submit form action
  // )
  // show form (2 messages behind if user has submitted), or show last message if form is the last message
  const messageToShow =
    lastMessage && lastMessage.createdBy === 'user' && !SHOW_USER_FORM_RESPONSE ? messages[messages.length - 2] : lastMessage

  return (
    <Grid container>
      {
        WIDE_MODE
        &&
        <Hidden smDown>
          <SideBar
            handleEndChatBtn={handleEndChatBtn}
            sendMessage={sendMessage}
            webSocket={webSocket}
            sessionEndedByUser={sessionEndedByUser}
          />
        </Hidden>
      }
      <Grid
        item
        sm={12}
        md={WIDE_MODE ? 9 : 12}
        container
        className={classes.chatAreaBackground}
      >
        <div
          className={clsx(classes.chatArea, chatIsOpen && 'display')}
          data-testid='chat-area-container'
        // {...(ENABLE_FILE_UPLOAD ? getRootProps(): {})}
        >
          <input
            {...getInputProps()}
            accept="image/*, video/*, .pdf, .doc, .docx, .xls, .xslx, .pptx, .ppt" // video/* or image/* or image/*,.pdf
            className={classes.input}
            id="file-upload-input"
            type="file"
            ref={fileUploadInputRef}
            style={{ display: 'none' }}
            disabled={!webSocket || sessionEndedByUser}
            onChange={handleInputFiles}
          />
          {
            ENABLE_FILE_UPLOAD && <Backdrop className={classes.backdrop} open={isDragActive} style={{ zIndex: 600, color: '#ffffff' }}>
              Drop the files here ...
            </Backdrop>
          }
          <Grid container>
            <PersistentMenu
              menuOpen={menuOpen}
              toggleMenu={toggleMenu}
              WIDE_MODE={WIDE_MODE}
              items={NAVIGATION_ITEMS}
              sendMessage={sendMessage}
            />
            <Form
              form={showForm ? messageToShow : null}
              open={showForm}
              sendForm={sendForm}
              WIDE_MODE={WIDE_MODE}
            />
            {
              sessionEndedByUser && ENABLE_FEEDBACK //if session was manually ended by user and feedback page was enabled, show the postSessionScreen
                ?
                <PostSessionScreen
                  handleEndChatBtn={handleEndChatBtn}
                  windowSize={windowSize}
                  endFeedback={endFeedback}
                  sendForm={sendForm}
                  webSocket={webSocket}
                />
                :
                messages.length > 0 || !WELCOME_SCREEN_SETTINGS.display //else if messages are available (user has started conversation)
                  ?
                  <ChatActiveScreen
                    focus={focus}
                    windowSize={windowSize}
                    handleEndChatBtn={handleEndChatBtn}
                    messages={messages}
                    sendMessage={sendMessage}
                    loading={loading}
                    webSocket={webSocket}
                    notificationVisible={notificationVisible}
                    setNotificationVisible={setNotificationVisible}
                    disconnectedDialogOpen={disconnectedDialogOpen}
                    toggleDialog={toggleDialog}
                    restartSocket={restartSocket}
                    toggleOpenState={toggleOpenState}
                  />
                  :
                  <WelcomeScreen
                    windowSize={windowSize}
                    unMountClass={unMountClass}
                    sendMessage={sendMessage}
                    socketReady={socketReady}
                  /> //show welcome screen if user has not begun conversation
            }
            {
              (
                (WELCOME_SCREEN_SETTINGS.actionButton.active && messages.length > 0 && !sessionEndedByUser) //Welcome screen is enabled and no messages have loaded
                ||
                (!WELCOME_SCREEN_SETTINGS.actionButton.active && !sessionEndedByUser)
              ) //if we are showing the feedback page, hide the chat input component
              &&
              <UserInput
                triggerInputFocusScroll={triggerInputFocusScroll}
                inputDisabled={!webSocket || sessionEndedByUser}
                toggleMenu={toggleMenu}
                sendMessage={sendMessage}
                resetUserMsg={resetUserMsg}
                sendFile={sendFile}
                files={files}
                resetInput={resetInput}
                sendBtnRef={sendBtnRef}
              />
            }
          </Grid>
        </div>
      </Grid>
      {
        SHOW_WATERMARK &&
        <div className={classes.copyrightLabel}>
          Powered by <a href='https://www.pand.ai' target='_blank'>Pand.AI</a>
        </div>
      }
    </Grid>
  );
}